import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Photos1 from '../../../images/work/19_PB_PORTOLA/PHOTOS/PO_01.jpg';
import Photos2 from '../../../images/work/19_PB_PORTOLA/PHOTOS/PO_02.jpg';
import Photos3 from '../../../images/work/19_PB_PORTOLA/PHOTOS/PO_03.jpg';
import Photos4 from '../../../images/work/19_PB_PORTOLA/PHOTOS/PO_04.jpg';
import Photos5 from '../../../images/work/19_PB_PORTOLA/PHOTOS/PO_05.jpg';
import Photos6 from '../../../images/work/19_PB_PORTOLA/PHOTOS/PO_06.jpg';
import Photos1s from '../../../images/work/19_PB_PORTOLA/PHOTOS/450X280/PO-SM_01.jpg';
import Photos2s from '../../../images/work/19_PB_PORTOLA/PHOTOS/450X280/PO-SM_02.jpg';
import Photos3s from '../../../images/work/19_PB_PORTOLA/PHOTOS/450X280/PO-SM_03.jpg';
import Photos4s from '../../../images/work/19_PB_PORTOLA/PHOTOS/450X280/PO-SM_04.jpg';
import Photos5s from '../../../images/work/19_PB_PORTOLA/PHOTOS/450X280/PO-SM_05.jpg';
import Photos6s from '../../../images/work/19_PB_PORTOLA/PHOTOS/450X280/PO-SM_06.jpg';

import Process1 from '../../../images/work/19_PB_PORTOLA/PROCESS/POR_01.jpg';
import Process2 from '../../../images/work/19_PB_PORTOLA/PROCESS/POR_02.jpg';
import Process3 from '../../../images/work/19_PB_PORTOLA/PROCESS/POR_03.jpg';
import Process4 from '../../../images/work/19_PB_PORTOLA/PROCESS/POR_04.jpg';
import Process5 from '../../../images/work/19_PB_PORTOLA/PROCESS/POR_05.jpg';
import Process1s from '../../../images/work/19_PB_PORTOLA/PROCESS/450X280/POR-SM_01.jpg';
import Process2s from '../../../images/work/19_PB_PORTOLA/PROCESS/450X280/POR-SM_02.jpg';
import Process3s from '../../../images/work/19_PB_PORTOLA/PROCESS/450X280/POR-SM_03.jpg';
import Process4s from '../../../images/work/19_PB_PORTOLA/PROCESS/450X280/POR-SM_04.jpg';
import Process5s from '../../../images/work/19_PB_PORTOLA/PROCESS/450X280/POR-SM_05.jpg';

const Residence19 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="PEBBLE BEACH RESIDENCE"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={[
          'Remodel of existing 3,655 SF single family residence with outdoor improvements.',
          'Design - 2018',
          'Construction - 2019',
        ]}
        testimonialList={[]}
        photos={[Photos1, Photos2, Photos3, Photos4, Photos5, Photos6]}
        photosSmall={[
          Photos1s,
          Photos2s,
          Photos3s,
          Photos4s,
          Photos5s,
          Photos6s,
        ]}
        processPhotos={[Process1, Process2, Process3, Process4, Process5]}
        processPhotosSmall={[
          Process1s,
          Process2s,
          Process3s,
          Process4s,
          Process5s,
        ]}
      />
    </Container>
  </Layout>
);

export default Residence19;
